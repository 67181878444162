import type { Legal } from "$docs/types"

const _legal: Legal = {
    "article": {
        "title": "Cookie Policy",
        "content_html": "<h1 id=\"Cookie Policy\">Cookie Policy</h1>\n<p>GroupTube exclusively uses essential cookies that are necessary for our service's operation. Below you can find a list of all used cookies and their purpose.</p>\n<p>As we do not use cookies to track users or gather user-related data, you are not prompted to grant permission for the use of non-essential cookies, since there are none.</p>\n<hr>\n<h2 id=\"List of Cookies\">List of Cookies</h2>\n<ul>\n<li><code>token_renew</code> is responsible for maintaining your login status across restarts or page refreshes</li>\n</ul>\n",
        "first_section": "GroupTube exclusively uses essential cookies that are necessary for our service's operation. Below you can find a list of all used cookies and their purpose."
    },
    "meta": {
        "modified_at": "2023-11-05T12:27:00.000Z",
        "topic": "Legal"
    },
    "seo": {
        "title": "Cookie Policy",
        "description": "GroupTube exclusively uses essential cookies that are necessary for our service's operation. Below you can find a list of all used cookies and their purpose."
    }
}

export default _legal
