import type { Legal } from "$docs/types"

const _legal: Legal = {
    "article": {
        "title": "Imprint",
        "content_html": "<h1 id=\"Imprint\">Imprint</h1>\n<h2 id=\"GroupTube\">GroupTube</h2>\n<h3 id=\"Contact\">Contact</h3>\n<p>web: <a href=\"https://group.tube\" target=\"_blank\">group.tube</a><br>\nemail: <a href=\"mailto:hello@group.tube\">hello@group.tube</a></p>\n",
        "first_section": "GROUPTUBE"
    },
    "meta": {
        "modified_at": "2024-02-20T12:27:00.000Z",
        "topic": "Legal"
    },
    "seo": {
        "title": "Imprint",
        "description": "GROUPTUBE"
    }
}

export default _legal
